import React from "react";
import {Outlet} from "react-router-dom";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

function GuestLayout() {
    return (
        <div className="site">
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

export default GuestLayout;