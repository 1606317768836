import React from "react";
import Home from "./view/Home";
import Safety from "./view/Safety";
import Contact from "./view/Contact";
import Faq from "./view/Faq";
import GuestLayout from "./view/layout";
import {Route, Routes} from "react-router-dom";

import i18next from "i18next";
import {I18nextProvider, initReactI18next} from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationDU from "./locales/du/translation.json";
import {DEFAULT_LANGUAGE} from "./configs/AppConfig";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hire from "./view/Hire";
import PrivacyPolicy from "./view/PrivacyPolicy";
import TermsConditions from "./view/TermsConditions";
import Support from "./view/Support";
import PageNotFound from "./view/PageNotFound";

const resources = {
    en: {
        translation: translationEN,
    },
    du: {
        translation: translationDU,
    }
};
i18next.use(initReactI18next).init({
    resources,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
        escapeValue: false,
    },
});

function App() {
    return (
        <>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick
                            rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"
                            style={{zIndex: "999999"}}/>
            <I18nextProvider i18n={i18next}>
                <Routes>
                    <Route path={'/'} element={<GuestLayout/>}>
                        <Route path={'/'} element={<Home/>}/>
                        <Route path={'/safety'} element={<Safety/>}/>
                        <Route path={'/faq'} element={<Faq/>}/>
                        <Route path={'/hire'} element={<Hire/>}/>
                        <Route path={'/privacy-policy'} element={<PrivacyPolicy/>}/>
                        <Route path={'/terms-conditions'} element={<TermsConditions/>}/>
                        <Route path={'/support'} element={<Support/>}/>
                        <Route path={'/contact'} element={<Contact/>}/>

                        <Route path={'/404'} element={<PageNotFound/>}/>
                        <Route path={'/*'} element={<PageNotFound/>}/>
                    </Route>

                    <Route path="/:lang" element={<GuestLayout/>}>
                        <Route path='/:lang/' element={<Home/>}/>
                        <Route path='/:lang/safety' element={<Safety/>}/>
                        <Route path={'/:lang/faq'} element={<Faq/>}/>
                        <Route path={'/:lang/contact'} element={<Contact/>}/>
                        <Route path={'/:lang/hire'} element={<Hire/>}/>
                        <Route path={'/:lang/privacy-policy'} element={<PrivacyPolicy/>}/>
                        <Route path={'/:lang/terms-conditions'} element={<TermsConditions/>}/>
                        <Route path={'/:lang/support'} element={<Support/>}/>
                    </Route>
                </Routes>
            </I18nextProvider>
        </>
    );
}

export default App;
