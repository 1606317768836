import {useTranslation} from "react-i18next";
import React from "react";

function Hire() {
    // eslint-disable-next-line
    const {t} = useTranslation();
    return (<>
        <main className="site-main">
            <div className="site-banner">
                <div className="site-banner-thumb">
                    <img src="/images/safety-banner.jpg" alt="Safety Banner"/>
                </div>
                <div className="site-banner-wrap">
                    <div className="container">
                        <div className="site-banner-content">
                            <h1>Work With us </h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="site-section faq-section pt-0 pt-xl-4">
                <div className="container">
                    <h3 className="text-center">Coming Soon</h3>
                </div>
            </section>
        </main>
    </>);
}

export default Hire;