import React from "react";
import {useTranslation} from "react-i18next";

function Safety() {
    // eslint-disable-next-line
    const {t} = useTranslation();
    return (
        <main className="site-main">
            <div className="site-banner">
                <div className="site-banner-thumb">
                    <img src="/images/safety-banner.jpg" alt="Safety Banner"/>
                </div>
                <div className="site-banner-wrap">
                    <div className="container">
                        <div className="site-banner-content">
                            <h1>Safety </h1>
                            <h6>Prepare by taking a look at our safety tips and tutorials.</h6>
                        </div>
                    </div>
                </div>
            </div>
            <section className="site-section tab-section">
                <div className="container">
                    <ul className="nav nav-tabs canoes-nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="safety-tab" data-bs-toggle="tab"
                                    data-bs-target="#safety-tab-pane" type="button" role="tab"
                                    aria-controls="safety-tab-pane" aria-selected="true">Safety
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="tutorials-tab" data-bs-toggle="tab"
                                    data-bs-target="#tutorials-tab-pane" type="button" role="tab"
                                    aria-controls="tutorials-tab-pane" aria-selected="false">Tutorials
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="safety-tab-pane" role="tabpanel"
                             aria-labelledby="safety-tab" tabIndex="0">
                            <div className="tab-main">
                                <div className="safety-tab-card">
                                    <div className="safety-logo">
                                        <img src="/images/logo.png" alt="logo"/>
                                    </div>
                                    <div className="safety-slider">
                                        <div>
                                            <div className="safety-slide">
                                                <div className="safety-slide-thumb">
                                                    <div className="safety-slide-thumb-inner">
                                                        <img src="/images/safety1.png" alt="Safety"/>
                                                    </div>
                                                </div>
                                                <div className="safety-slide-content">
                                                    <p>Read our safety tips to learn how to stay safe on the water</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="safety-slide">
                                                <div className="safety-slide-thumb">
                                                    <div className="safety-slide-thumb-inner">
                                                        <img src="/images/safety2.png" alt="Safety"/>
                                                    </div>
                                                </div>
                                                <div className="safety-slide-content">
                                                    <p>Read our safety tips to learn how to stay safe on the water</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="safety-slide">
                                                <div className="safety-slide-thumb">
                                                    <div className="safety-slide-thumb-inner">
                                                        <img src="/images/safety3.png" alt="Safety"/>
                                                    </div>
                                                </div>
                                                <div className="safety-slide-content">
                                                    <p>Read our safety tips to learn how to stay safe on the water</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="safety-slide">
                                                <div className="safety-slide-thumb">
                                                    <div className="safety-slide-thumb-inner">
                                                        <img src="/images/safety4.png" alt="Safety"/>
                                                    </div>
                                                </div>
                                                <div className="safety-slide-content">
                                                    <p>Read our safety tips to learn how to stay safe on the water</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="safety-slide">
                                                <div className="safety-slide-thumb">
                                                    <div className="safety-slide-thumb-inner">
                                                        <img src="/images/safety5.png" alt="Safety"/>
                                                    </div>
                                                </div>
                                                <div className="safety-slide-content">
                                                    <p>Read our safety tips to learn how to stay safe on the water</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="safety-tab-content">
                                    <h5>General safety regulations</h5>
                                    <p>Paddle at your own risk!</p>
                                    <p>ALWAYS WEAR A LIFE JACKET!</p>
                                    <p>If any paddler in your group weighs less than 35 kg or more than 100 kg, they
                                        must supply their own life jacket. Check the weather beforehand. Don't paddle
                                        beyond your skill level in high waves, inclement weather etc.</p>
                                    <p><strong>Make sure to always adhere to the following safety guidelines:</strong>
                                    </p>
                                    <ul>
                                        <li>Don't paddle alone</li>
                                        <li>Stay close to the shore</li>
                                        <li>Wear clothing appropriate to the conditions = DRY SUIT</li>
                                        <li>Do not drink (alcohol) or do drugs and paddle</li>
                                        <li>Do not paddle after dark</li>
                                        <li>Do not exceed the weight capacity of the kayak</li>
                                        <li>Practice self-rescue and re-boarding before going out</li>
                                        <li>Stay out of motorboat paths</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="tutorials-tab-pane" role="tabpanel"
                             aria-labelledby="tutorials-tab" tabIndex="0">
                            <div className="tab-main">
                                Tutorials Content
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Safety;