import Axois from "axios";
import {API_URL} from "../configs/AppConfig";

const AxoisInstance = Axois.create({
    baseURL: API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-Type": "text/plain",
        accept: "*",
    }
});

export default AxoisInstance;